import { keyframes } from '@emotion/react'

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  50%  {
    opacity:1;
  }
  100% {
    opacity:0;
  }
`

export default {
  // ? =================================
  // ? =====  Multilocation Page  ======
  // ? =================================

  multiLocationPageAbout: {
    minHeight: ['', '', '100vh'],
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      variant: 'customVariants.ctaButton'
    }
  },

  multiLocationPageAbout2: {
    padding: '6rem 1rem 6rem',
    backgroundColor: '#efefef',
    '.title': {
      marginBottom: '2.5rem'
    },
    '.text': {
      color: 'black'
    },
    display: 'flex',
    alignItems: 'center',
    '.section': {
      height: 'fit-content',
      // borderBottom: 'double 9px black',
      // borderTop: 'double 9px black',
      padding: '2rem'
    }
  },

  multiLocationPageAbout3: {
    // height: '70vh',
    color: 'white',
    padding: ['5rem 1rem'],
    '.title': {
      color: 'primary',
      fontWeight: '300',
      fontSize: ['1.5rem', '', '2rem']
    },
    '.text': {
      color: 'white',
      fontSize: ['0.9rem', '1.1rem']
    },
    display: 'flex',
    alignItems: 'center',
    '.section': {
      backgroundColor: '#000000cc',
      border: 'solid 2px',
      borderColor: 'primary',
      height: 'fit-content',
      padding: '1.5rem'
    }
  },

  multiLocationPageEvents: {
    padding: ['0rem', '', '4rem 2rem', '5rem 4rem 10rem'],
    '.contentBoxes': {
      padding: '0rem',
      'a:nth-of-type(even)': {
        position: ['', '', 'relative'],
        top: ['', '', '9rem']
      }
    },
    '.box': {
      padding: '1rem',
      width: ['100%', '', '50%'],
      minHeight: '50vh'
    },
    '.image': {
      // order: '4',
      objectFit: 'cover',
      minHeight: '50vh'
    },
    '.title': {
      variant: 'customVariants.title',
      padding: ['1rem', '', '1rem', '2rem 3rem 1rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      padding: ['1rem', '', '1rem', '2rem 3rem 1rem']
    },
    '.text': {
      variant: 'customVariants.text',
      padding: ['1rem', '', '1rem', '2rem 3rem 1rem']
    }
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    // '> .container': {
    //   padding: ['0.5rem', '0.5rem', '0.5rem', '4.5rem 1rem'],
    // },

    // '> .containerScrolled': {
    //   padding: ['0.5rem', '0.5rem', '0.5rem', '4.5rem 1rem'],
    // },

    '.container': {
      '.navItemDropdownMenu': {
        background: 'transparent',
        width: 'fit-content'
      }
    },

    '.logoLocationContainer': {
      // display: 'none',
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      '.container': {
        // padding: ['0.5rem'],
      },
      '.logo': {
        maxWidth: '150px',
        marginBottom: '0.5rem'
      },
      '.locationSwitcher': {
        justifyContent: 'center',
        button: {
          fontSize: '0.8rem'
        }
      },
      '.popUp': {
        transform: 'unset',
        '.content': {
          backgroundColor: 'background',
          '.logo': {
            filter: 'brightness(0)',
            maxWidth: '200px'
          },
          '.title': {
            marginBottom: '1.5rem',
            fontSize: '1.25rem'
          },

          '.locations': {
            a: {
              variant: 'buttons.primary'
            }
          }
        }
      }
    },

    '.smallNavMenu': {
      '.navItem': {
        // color: 'dark',
        color: 'text',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          ':hover': {
            color: 'lightgrey',
            textDecoration: 'underline'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          padding: '1rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'white',
      div: {
        backgroundColor: 'dark'
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundColor: 'white',
      width: ['85%', '60%', '50%', '40%'],
      '.navItemDropdownMenu': {
        background: 'transparent',
        width: 'fit-content',
        position: 'static',
        '.navItem': {
          fontSize: ['0.9rem', '1rem']
        }
      },
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '300px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    },

    '.containerScrolled': {
      backgroundColor: 'primary',
      '.navItemDropdownMenu': {
        width: 'fit-content'
      },
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', '0 auto 0 0'],
        position: ['', '', '', 'static'],
        '.container': {
          // padding: ['0.5rem'],
        },
        '.logoScrolled ': {
          maxWidth: '150px',
          marginBottom: '0.5rem',
          maxheight: 'unset',
          filter: 'brightness(0) invert(1)',
          display: 'inline',
          img: {
            maxHeight: 'unset',
            maxWidth: '150px',
            height: 'unset'
          }
        },
        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      },
      '.smallNavMenu': {
        '.navItem': {
          // color: 'dark',
          color: 'light',
          margin: ['', '', '', '0.75rem', '1rem'],
          a: {
            fontSize: '1rem',
            ':hover': {
              color: 'lightgrey',
              textDecoration: 'underline'
            }
          }
        },
        '.reservations': {
          // margin: '0rem',
          a: {
            padding: '1rem',
            border: 'solid 1px',
            borderColor: 'light'
          }
        }
      }
    }
  },

  footer: {
    padding: ['0rem', '', '', '0rem'],
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['', '', '', 'stretch'],
    borderTop: '2px solid',
    borderColor: 'primary',
    '::after': {
      content: `"© ${new Date().getFullYear()} Scalini Fedeli | New York | New Jersey"`,
      width: '100%',
      backgroundColor: 'primary',
      color: 'white',
      padding: '2rem 0rem',
      marginBottom: ['40px', '', '', '0px'],
      textTransform: 'uppercase',
      order: '6',
      textAlign: 'center',
      fontFamily: 'heading'
    },
    '.logo': {
      maxWidth: '200px'
    },
    '.contactDetails-container': {
      margin: '2rem 0rem'
    },
    '.sectionHeading': {
      color: 'black',
      borderBottom: '0rem',
      fontSize: '1.5rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      marginBottom: ['0rem', '', '', '1rem']
    },
    '.column': {
      marginBottom: '0rem',
      padding: '3rem 2rem',
      width: ['', '', '', '20%'],
      flexGrow: '1',
      maxWidth: ['unset', '', '', ''],
      justifyContent: 'flex-start'
    },
    '.contactColumn': {
      borderRight: 'none !important',
      padding: '3rem 2rem !important'
    },

    '.aboutColumn': {
      backgroundColor: 'background'
    },

    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: 'background',
      // margin: ['', '', '', '0 0 0 auto'],
      // marginBottom: '0rem',
      // padding: ['', '', '4rem 3rem', '4rem 4rem'],
      // width: ['100%', '', '', '40%'],

      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'primary',
          fontSize: ['1rem', '', '1.25rem'],
          letterSpacing: '1px',
          fontWeight: '600',
          padding: '0.25rem 0rem'
        }
      }
    },
    '.poweredBy  .gonationLogo': {
      filter: 'unset'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'black'
    },
    '.footerAboutButton span': {
      variant: 'buttons.primary',
      fontSize: '0.9rem',
      padding: '0.75rem 1.5rem'
    },
    '.button': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      padding: '0.75rem 1.5rem'
    },
    '.aboutText': {
      color: 'text'
    },
    '.popUp': {
      '.content': {
        padding: '3rem 1rem',
        '.title': {
          fontSize: '1.5rem',
          opacity: '0.7'
        }
      },
      '.locations': {
        a: {
          variant: 'buttons.primary'
        }
      }
    }
  },

  footerBar: {
    background: 'transparent',
    backgroundColor: 'primary',
    padding: '1.5rem',
    order: '10',
    '.section': {
      maxWidth: '100%',
      width: '100%',
      color: 'white'
    },
    '.title': {
      color: 'white',
      fontSize: ['1.5rem'],
      margin: '0rem'
    }
  },

  // ? =============================
  // ? =======  reuseables  ========
  // ? =============================

  title: {
    fontSize: ['2rem', '', '', '2.5rem'],
    order: '1',
    textTransform: 'uppercase',
    width: 'fit-content'
  },
  subtitle: {
    fontWeight: '300',
    margin: '0rem 0rem 1.5rem',
    order: '2',
    fontSize: ['1.5rem', '', '', '2rem'],
    opacity: '0.6'
  },

  text: {
    order: '3',
    fontSize: '0.8rem',
    lineHeight: '1.75'
  },

  ctaButton: {
    order: '4',
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '2rem', '3rem'],
    minHeight: ['', '', '80vh'],
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      paddingLeft: '2rem'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      variant: 'customVariants.ctaButton'
    }
  },

  // ? =======================
  // ? =====  Homepage  ======
  // ? =======================

  homepageHero: {
    backgroundColor: 'white',
    '.slick-slider': {
      // width: ['100%', '100%', '100%', '60%'],
      width: ['100%', '', '', '60%', '65%'],

      // order: ['', '', '', '2'],
      '.slick-prev, .slick-next': {
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'primary',
        height: '30px'
      },
      '.slick-prev': {
        // right: '1rem',
      },
      '.slick-next': {
        // right: '3rem',
      },
      '.slick-dots': {
        left: '1rem',
        right: 'unset',
        display: 'none'
      },
      '.slick-dots li button:before': {
        color: 'white',
        display: 'none'
      }
    },
    '.hero_content_container': {
      width: ['100%', '', '', '40%', '35%'],
      padding: ['3rem 1.5rem', '', '', '2.5rem', '3.5rem'],
      '.title': {
        variant: 'customVariants.title',
        borderBottom: '2px solid black'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontFamily: 'display',
        textAlign: ['', '', '', 'right'],
        width: '100%',
        fontSize: ['2.25rem', '', '', '3rem'],
        marginBottom: '1rem'
      },
      '.text': {
        variant: 'customVariants.text'
      }
    }
  },
  homepageHeroShout: {
    backgroundColor: ['', '', '#ffffff7a', '#ffffff7a'],
    marginBottom: ['1rem', '', '', '0rem'],
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.25rem', '1.5rem', '', ''],
      '::after': {
        backgroundColor: 'primary'
      }
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: '1.25rem'
    },
    '.date': {
      color: 'dark'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.secondary',
        marginRight: '0.5rem',
        padding: '0.5rem',
        fontSize: '0.9rem'
        // backgroundColor: '',
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'white'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageReservation: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['6rem 1rem', '', '10rem 1rem'],
    flexDirection: 'column',
    backgroundColor: 'white',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  homepagePrivateDining: {
    variant: 'customVariants.homepageContact'
  },

  homepageBoxes: {
    padding: ['0rem', '', '1rem', '1rem'],

    '.box': {
      borderRadius: '0px',
      width: ['100%', '', 'calc(50% - 2rem)'],
      margin: '1rem',
      height: '75vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: ['1rem', '', '3rem'],
      color: 'white',
      overflow: 'hidden',
      alignItems: 'center',
      textAlign: 'center',
      '::before': {
        content: "''",
        width: '90%',
        position: 'absolute',
        height: '70%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '-1',
        border: 'solid 2px',
        borderColor: 'primary'
      },
      '.title': {
        variant: 'customVariants.title',
        zIndex: '2'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        zIndex: '2'
      },
      '.text': {
        variant: 'customVariants.text',
        color: 'white',
        zIndex: '2',

        p: {
          color: 'white'
        }
      },
      a: {
        order: '4'
      },
      '.image': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        zIndex: '0',
        filter: 'brightness(0.8)'
      },
      '.ctaLink': {
        variant: 'buttons.primary',
        order: '4',
        margin: '0rem auto',
        borderColor: 'white',
        color: 'white',
        zIndex: '2',
        ':hover': {
          backgroundColor: 'transparent',
          color: 'white'
        }
      }
    }
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '8rem 1rem'],
    maxWidth: 'unset',
    backgroundColor: 'white',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'white',
    '.title': {
      variant: 'customVariants.title',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      p: {
        color: 'white'
      }
    },

    '.ctaButton': {
      variant: 'buttons.primary',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'white'
      }
    }
  },

  //  ? ======================
  //  ? ====  About page  ====
  //  ? ======================

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      lineHeight: '1',
      color: 'primary'
    }
  },

  aboutOwner: {
    variant: 'customVariants.sideBySide1',
    '.image': {
      objectFit: 'contain',
      objectPosition: 'top'
    }
  },

  //  ? ========================
  //  ? ======  Menu page  =====
  //  ? ========================

  menuPDFMenu: {
    paddingBottom: '0rem',
    '.content': {
      display: 'none'
    }
  },

  menu: {
    border: 'solid 1px black',
    padding: '1rem',
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'white',
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', 'calc(50% - 1rem)', 'calc(50% - 1rem)', 'calc(50% - 1rem)'],
      borderRadius: '0px'
    },
    '.menuCell': {
      borderRadius: '0px'
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      margin: '0rem 0rem 2rem',
      padding: '1rem',
      backgroundColor: 'primary',
      color: 'white',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '2rem']
    },
    '.menuSectionDescription': {
      margin: '0rem auto 1.5rem',
      fontSize: '1.1rem',
      maxWidth: '800px'
    },
    '.menuItemDescription': {
      color: 'primary'
    },
    '.menuPriceContainer': {
      display: 'none'
    },
    '.variantsContainer': {
      display: 'none'
    }
  },

  menuCTAS: {
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      margin: '0 auto'
    }
  },

  //  ? ========================
  //  ? ====  Gallery page  ====
  //  ? ========================

  gallery: {
    // '.albumsContainer': {
    //   display: 'none',
    // },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  //  ? ========================
  //  ? ====  Contact page  ====
  //  ? ========================

  contactForm: {
    padding: '6rem 1rem',
    order: '4',
    backgroundColor: '#f0f0ee',
    '.title': {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.7',
      fontWeight: '200',
      color: 'black',
      marginBottom: '2rem'
    },
    '.text': {},
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },
      '.submitBtn': {
        borderRadius: '0px'
      }
    }
  },

  locationMap: {
    order: '3',
    backgroundColor: '#e4e4e4',
    '.content_container': {
      padding: '4rem 1rem'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  //  ? =============================
  //  ? ====  Private Room page  ====
  //  ? =============================

  privateroom: {
    width: '100%',
    maxWidth: 'unset',
    backgroundColor: 'transparent',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    borderRadius: '0px',
    marginBottom: '0rem',
    '.innerMenuContainer': {
      padding: '3rem 1rem'
    },
    '.allInContainerWrapper': {
      backgroundColor: 'transparent'
    },
    '.menuSectionTitle': {
      display: 'none'
    },

    '.menuSectionDescription': {
      maxWidth: '767px',
      margin: '0rem auto 2rem',
      lineHeight: '1.75',
      fontSize: '1.25rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(50% - 2rem)', 'calc(50% - 2rem)'],
      margin: ['', '', '1rem'],
      border: 'solid 1px black'
    },

    '.menuItemImageContainer': {
      height: '400px',
      width: '100%'
    },
    '.menuItemContentContainer ': {
      height: 'auto',
      padding: ['1rem', '2rem', '1rem', '2rem'],
      flexGrow: '1',
      alignItems: 'center'
      // justifyContent: 'center',
    },

    '.menuItemNamePriceContainer': {
      width: '100%'
    },

    '.menuItemName': {
      marginBottom: '2rem',
      width: '100%',
      fontSize: ['1.5rem', '', '2rem', '2.5rem'],
      borderBottom: '1px solid',
      paddingBottom: '0.5rem'
    },
    '.menuItemDescription': {
      width: '100%'
    },
    '.menuItemImgDefault, .menuItemImgDefaultContainer': {
      display: 'none'
    }
  }
}
